import React from 'react';
import img from '../images/knowledge-isometric.svg';

const Intro = () => {
    return (
        <>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={img} />
                        </div>
                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            
                        <h3 className="text-3xl  text-blue-900 
                            font-bold">Nuestro <span className='font-black'>Trabajo</span></h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>La Fundación Bio Pacífico Colombia, legalmente establecida desde 2018, se dedica al progreso de la comunidad afrodescendiente, abordando áreas como la educación, cultura, medio ambiente y tecnología. Nuestro compromiso se centra en mejorar la calidad de vida y promover la equidad, con especial énfasis en la población de la costa pacífica colombiana.</p>
                            </div>
                            
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>Hemos beneficiado a más de 380 jóvenes, asegurando su acceso a la educación superior y destacando la importancia de la resolución 097 de 2003, que reserva el 8% de los cupos universitarios para afrocolombianos. A través de nuestra labor, buscamos no solo informar sobre estas oportunidades, sino también inspirar a los jóvenes a alcanzar su máximo potencial.</p>
                            </div>
                           {/* <Link to="/contact" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group">
                                Contáctanos
                                <svg className="w-4 h-4 ml-1 group-hover: translate-x-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </Link>*/}
                        </div>
                    </div>
                </div>
        </>
    )
}

export default Intro;