import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Importa los estilos predeterminados
import NavBar from '../components/Navbar/NavBar';
import image1 from '../images/Carrusel/im1.JPG';
import image2 from '../images/Carrusel/im2.JPG';
import image3 from '../images/Carrusel/im3.JPG';
import image5 from '../images/Carrusel/im5.JPG';
import image6 from '../images/Carrusel/im6.JPG';
import image7 from '../images/Carrusel/im7.JPG';
import image8 from '../images/Carrusel/im8.JPG';
import image9 from '../images/Carrusel/im9.JPG';
import image10 from '../images/Carrusel/im10.JPG';
import image11 from '../images/Carrusel/Venenosa 1.jpg';
import image12 from '../images/Carrusel/Venenosa 20.jpg';
import image13 from '../images/Carrusel/Venenosa 3.jpeg';

const CarouselComponent = () => {
    const images = [image10, image11,image1, image8, image12,image9, image2, image7, image6, image3, image5,image13];

    return (
        <>
            <NavBar />
            <div className="CarouselComponent pt-24 mx-auto mb-16" style={{ maxWidth: '1200px' }}>
                <Carousel
                    showArrows={true}
                    infiniteLoop={true}
                    autoPlay={true}
                    interval={2500}
                    showThumbs={false}
                    showStatus={false}
                    useKeyboardArrows={true}
                    swipeable={true}
                    dynamicHeight={true}
                    emulateTouch={true}
                    showIndicators={true}
                    className="rounded-lg overflow-hidden"
                >
                    {images.map((image, index) => (
                        <div key={index} className="carousel-slide">
                            <img src={image} alt={`Slide ${index}`} className="object-cover"/>
                        </div>
                    ))}
                </Carousel>
            </div>
        </>
    );
};

export default CarouselComponent;
