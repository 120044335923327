import React from 'react';
import ReactDOM from 'react-dom';

const VideoModal = ({ isOpen, videoId, onClose }) => {
    if (!isOpen) return null;

    const modalContent = (
        <div className="fixed inset-0 z-50 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center" onClick={onClose}>
            <div className="relative bg-white rounded-lg shadow-xl" onClick={e => e.stopPropagation()}>
                <div className="flex justify-end p-2">
                    <button onClick={onClose} className="text-gray-400 hover:text-gray-600 transition ease-in-out duration-150">
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                    </button>
                </div>
                <div className="flex justify-center items-center p-4">
                    <iframe
                        title="Video Preview"
                        src={`https://drive.google.com/file/d/${videoId}/preview`}
                        width="1280"
                        height="720"
                        frameBorder="0"
                        allow="autoplay"
                        allowFullScreen
                    ></iframe>
                </div>
            </div>
        </div>
    );


    return ReactDOM.createPortal(
        modalContent,
        document.getElementById('modal-root')
    );
};

export default VideoModal;
