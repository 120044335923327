import React from 'react';
import NavBar from '../components/Navbar/NavBar';

const Portfolio = () => {
    return (
        <>
    <div className="portfolio" id='portfolio'>
        <div>
            <NavBar />
        </div>

        <div className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-20 h-5/6 " >
            <div className="my-4 py-4">
                <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Acciones Realizadas</h2>
                <div className='flex justify-center'>
                    <div className='w-24 border-b-4 border-blue-900'></div>
                </div>
                <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-grey-1500">Nuestra fundación tiene como habilidad perseguir fines de beneficencia, el interés o utilidad común y el mejoramiento de la calidad de vida, el bienestar común o de interés social nuestra entidad.</h2>
            </div>

            <div className="px-4" data-aos="fade-down" data-aos-delay="300">
                    <div className="grid sm:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 gap-5 max-w-6xl mx-auto"> 

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-10 h-16"> 	Fundación Talentos del Pacífico </h4>
                                <p className="text-lg font-medium leading-5 h-auto md:h-48">
                                <span className='font-black'>OBJETO:</span> Desarrollar un programa de acompañamiento psicosocial a través de actividades, lúdicas deportivas y  culturales en niños,  niñas y adolescentes  de 0 a 17 años  en el municipio de Jamundí  Valle del Cauca. 
                                </p>
                                <div className="">
                                    <li> <span className='font-black'>Lugar de ejecución:</span> Jamundí – Valle </li>
                                    <li> <span className='font-black'>Beneficiarios:</span> 400 </li>
                                </div>
                            </div>

                            
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-10 h-16"> Fundación para el Desarrollo Social, Ambiental y Tecnológica	(FUNDESATEC)</h4>
                                <p className="text-lg font-medium leading-5 h-auto md:h-48">
                                <span className='font-black'>OBJETO:</span> Orientar psicológica y equidad de género a mujeres afrocolombianas víctimas del conflicto armado residentes en el municipio de Guapi Cauca.
                                </p>
                                <div className="">
                                    <li> <span className='font-black'>Lugar de ejecución:</span> Guapi - Cauca </li>
                                    <li> <span className='font-black'>Beneficiarios:</span> 450 </li>
                                </div>
                            </div>

                            
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-10 h-16"> Ministerio de tecnologías de la información y las comunicaciones </h4>
                                <p className="text-lg font-medium leading-5 h-auto md:h-48">
                                <span className='font-black'>OBJETO:</span> Producción de la serie VENENOSA en la categoría 3.3. para la producción de nueva serie de ficción de la Convocatoria No. 3 dirigida a Comunidades Negras, Afrodescendientes, Raizales y Palenqueras y a las Comunidades Rom.
                                </p>
                                <div className="">
                                    <li> <span className='font-black'>Lugar de grabación:</span> Bogotá </li>
                                </div>
                            </div>

                            
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-10 h-16">  Ministerio del Interior - La Corporación Colombia Internacional (CCI) </h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                <span className='font-black'>OBJETO:</span> PREICFES BAJO PLATAFORMA WEB PARA POBLACIÓN NEGRA, AFROCOLOMBIANA, RAIZAL Y PALENQUERA., el cual fue postulado, habilitado, evaluado, viabilizado y destinatario de recursos de cofinanciación dentro del marco de la convocatoria del Banco Proyectos de Fortalecimiento para Consejos Comunitarios y expresiones organizativas en las áreas Rurales y Urbanas de las Comunidades Negras, Afrocolombianas, Raizales y Palenqueras – NARP de 2020.
                                </p>
                                <div className="">
                                    <li> <span className='font-black'>Lugar de ejecución:</span> Guapi, Puerto tejada y Guachene - Cauca </li>
                                    <li> <span className='font-black'>Beneficiarios:</span> 300</li>
                                </div>
                            </div>

                            
                        </div>  

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-10 h-16"> 	 	Ministerio de Cultura - Crea Digital 2021 </h4>
                                <p className="text-lg font-medium leading-5 h-auto md:h-48">
                                <span className='font-black'>OBJETO:</span> Creación de una plataforma web la cual que fue postulada, habilitada, y ganadora del estímulo en la categoría pertenencia étnicas para la coproducción para la realización de juegos de video denominado “RAÍCES AFROTIC” 
                                </p>
                                <div className="">
                                    <li> <span className='font-black'>Lugar de ejecución:</span> Cali, Valle </li>
                                    <li> <span className='font-black'>Beneficiarios:</span> 120 </li>
                                </div>
                            </div>

                            
                        </div>


                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-10 h-16"> Universidad Nacional Abierta y a Distancia - Ministerio del Interior  </h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                <span className='font-black'>OBJETO:</span> PREICFES BAJO PLATAFORMA WEB PARA POBLACIÓN NEGRA, AFROCOLOMBIANA, RAIZAL Y PALENQUERA.”, el cual fue postulado, habilitado, evaluado, viabilizado y destinatario de recursos de cofinanciación dentro del marco de la convocatoria del Banco Proyectos de Fortalecimiento para Consejos Comunitarios y expresiones organizativas en las áreas Rurales y Urbanas de las Comunidades Negras, Afrocolombianas, Raizales y Palenqueras – NARP de 2021.
                                </p>
                                <div className="">
                                    <li> <span className='font-black'>Lugar de ejecución:</span> Guapi, Puerto tejada y Guachené - Cauca </li>
                                    <li> <span className='font-black'>Beneficiarios:</span> 300 </li>
                                </div>
                            </div>

                            
                        </div> 


                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-10 h-16"> Consejo Comunitario Playas Unidas </h4>
                                <p className="text-lg font-medium leading-5 h-auto md:h-48">
                                <span className='font-black'>OBJETO:</span> Capacitación en el manejo de residuos sólidos, cuidado y protección ambiental en habitantes del consejo comunitario playas unidas municipio de la tola Nariño.
                                </p>
                                <div className="">
                                    <li> <span className='font-black'>Lugar de ejecución:</span> La Tola - Nariño  </li>
                                    <li> <span className='font-black'>Beneficiarios:</span> 800 </li>
                                </div>
                            </div>

                            
                        </div>  


                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-10 h-16"> Corporación Autónoma Regional Del Valle – CVC </h4>
                                <p className="text-lg font-medium leading-5 h-auto md:h-48">
                                <span className='font-black'>OBJETO:</span> Aunar esfuerzos técnicos, recursos económicos y humanos para implementar la estrategia educativa: Proyectos ciudadanos de educación ambiental – PROCEDA, apoyar una iniciativa juvenil ambiental y evaluar los resultados de intervenciones en las estrategias PRAE, CIDEA y PROCEDA en el marco de la Política Nacional de Educación Ambiental, en localidades priorizadas por la Corporación”
                                </p>
                                <div className="">
                                    <li> <span className='font-black'>Lugar de ejecución:</span> Cartago y Roldanillo - Valle   </li>
                                    <li> <span className='font-black'>Beneficiarios:</span> 75 </li>
                                    <li> <span className='font-black'>Beneficiarios:</span> 1200 </li>
                                </div>
                            </div>

                            
                        </div> 

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-10 h-16"> Alcaldía Municipal De Mosquera -  Nariño  </h4>
                                <p className="text-lg font-medium leading-5 h-auto md:h-48">
                                <span className='font-black'>OBJETO:</span> Prestación de servicios profesionales para mejorar los resultados de las pruebas saber 11 direccionado a los estudiantes del municipio de Mosquera departamento de Nariño.
                                </p>
                                <div className="">
                                    <li> <span className='font-black'>Lugar de ejecución:</span> Mosquera – Nariño   </li>
                                    <li> <span className='font-black'>Beneficiarios:</span> 300 </li>
                                </div>
                            </div>
                        </div> 

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-10 h-16"> 	 Alcaldía Municipal De Mosquera -  Nariño   </h4>
                                <p className="text-lg font-medium leading-5 h-auto md:h-48">
                                <span className='font-black'>OBJETO:</span> Instalación de cámaras de vigilancia en la nueva sede de la alcaldía y el mantenimiento de las ya existentes, en el casco urbano del municipio de Mosquera-Nariño
                                </p>
                                <div className="">
                                    <li> <span className='font-black'>Lugar de ejecución:</span> Mosquera – Nariño   </li>
                                </div>
                            </div>
                        </div> 


                       
                                         
                    </div>
                </div>
            </div>
    </div>
</>
            

    )
}

export default Portfolio;