import React from 'react';
import Footer from '../components/Footer';
import CarouselComponent from '../components/CarouselComponent';



const Logros = () => {
    return (
        <>
        <div>
            <CarouselComponent />

        </div>
        
         <Footer />
         </>
 
 
     )
 }
 
 export default Logros;