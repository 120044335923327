import React from 'react';
import Footer from '../components/Footer';
import Portfolio from '../components/Portfolio';
import Clients from '../components/Clients';


const Logros = () => {
    return (
        <>
        <div>
            <Portfolio />
            <Clients />
        </div>
        
         <Footer />
         </>
 
 
     )
 }
 
 export default Logros;