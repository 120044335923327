import React, { useState } from 'react';
import img from '../images/knowledge-flatline.svg'; 
import img2 from '../images/watering-plant-outline.svg';
import img3 from '../images/recycling-flatline-7b2ae.svg';
import img4 from '../images/recycling-flatline.svg';
import img5 from '../images/watering-plant-flatline.svg';
import VideoModal from './VideoModal';

const Services = () => {


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentVideoId, setCurrentVideoId] = useState('');



    const spots = [
        {id: '1Hwajw_HGdOzRlxUdKmRqUKa6h-5jZ6t_', title: 'Gira de experiencia exitosa', description: '', img: img },
        {id: '1yo_0a_Tf4jtlDkzz978zRw2kUE3Fz10X', title: 'El cuidado de nuestras zonas verdes', description: '', img: img2 },
        {id: '1Q0Fy6rrD2D8WmVVcEpWs5Av6l187Pweo', title: 'Cascarillo, el reciclaje en nuestra comunidad', description: '', img: img3 },
        {id: '1_xPZy68K45-7N6MxjAy18zR0tTHbWlOr', title: 'Importancia del reciclaje', description: '', img: img4 },
        {id: '1D-qu3_DxZMtJASUlIBnc8qClsm2y9jXr', title: 'Nuestra huerta urbana', description: '', img: img5 }
    ];

    const openModal = (videoId) => {
        setCurrentVideoId(videoId);
        setIsModalOpen(true);
    };



    return (
        <div id="services" className="bg-gray-100 py-12" >

            <section>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="zoom-out">
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-blue-900 mb-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 512 512" className='fill-current'><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm50.7-186.9L162.4 380.6c-19.4 7.5-38.5-11.6-31-31l55.5-144.3c3.3-8.5 9.9-15.1 18.4-18.4l144.3-55.5c19.4-7.5 38.5 11.6 31 31L325.1 306.7c-3.2 8.5-9.9 15.1-18.4 18.4zM288 256a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"></path></svg>
                            </div>
                            <h3 className="text-3xl  text-blue-900 
                            font-bold">Nuestra <span className='font-black'>Misión</span></h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>
                                La Fundación Bio Pacífico Colombia busca transformar vidas, preferiblemente de personas de bajos recursos, mediante la ejecución de proyectos de responsabilidad global que orientan e impactan a sus beneficiarios, talento humano, familia y sociedad.
                                </p>
                            </div>
                        </div>
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-blue-900 mb-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 640 512" className='fill-current'><path d="M15 15C24.4 5.7 39.6 5.7 49 15l63 63V40c0-13.3 10.7-24 24-24s24 10.7 24 24v96c0 13.3-10.7 24-24 24H40c-13.3 0-24-10.7-24-24s10.7-24 24-24H78.1L15 49C5.7 39.6 5.7 24.4 15 15zM133.5 243.9C158.6 193.6 222.7 112 320 112s161.4 81.6 186.5 131.9c3.8 7.6 3.8 16.5 0 24.2C481.4 318.4 417.3 400 320 400s-161.4-81.6-186.5-131.9c-3.8-7.6-3.8-16.5 0-24.2zM320 320a64 64 0 1 0 0-128 64 64 0 1 0 0 128zM591 15c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-63 63H600c13.3 0 24 10.7 24 24s-10.7 24-24 24H504c-13.3 0-24-10.7-24-24V40c0-13.3 10.7-24 24-24s24 10.7 24 24V78.1l63-63zM15 497c-9.4-9.4-9.4-24.6 0-33.9l63-63H40c-13.3 0-24-10.7-24-24s10.7-24 24-24h96c13.3 0 24 10.7 24 24v96c0 13.3-10.7 24-24 24s-24-10.7-24-24V433.9L49 497c-9.4 9.4-24.6 9.4-33.9 0zm576 0l-63-63V472c0 13.3-10.7 24-24 24s-24-10.7-24-24V376c0-13.3 10.7-24 24-24h96c13.3 0 24 10.7 24 24s-10.7 24-24 24H561.9l63 63c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0z"></path></svg>
                            </div>
                            <h3 className="text-3xl  text-blue-900 font-bold">Nuestra <span className='font-black'>Visión</span></h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>Para 2032, aspiramos a ser distinguidos como la principal entidad en promover la inclusión y elevar la calidad de vida de las comunidades más desfavorecidas y económicamente vulnerables, estableciendo un modelo de impacto y transformación social sostenible.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section data-aos="zoom-in-down">
            <div className="my-4 py-4">
                <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Spots</h2>
                <div className='flex justify-center'>
                    <div className='w-24 border-b-4 border-blue-900'></div>
                </div>
                <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">Estamos comprometidos con el bienestar y progreso de nuestras comunidades.</h2>
            </div>

            <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-5">
                    {spots.map((spot, index) => (
                        <div
                            key={index}
                            className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group"
                            onClick={() => openModal(spot.id)}
                        >
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={spot.img} />
                                <h2 className="font-semibold my-4 text-2xl text-center">{spot.title}</h2>
                                <p className="text-md font-medium">{spot.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {isModalOpen && (
                <VideoModal
                    isOpen={isModalOpen}
                    videoId={currentVideoId}
                    onClose={() => setIsModalOpen(false)}
                />
            )}
        </section>
            
        </div>
    )
}

export default Services;